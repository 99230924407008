import React, { useState, useEffect } from 'react';
import { IMessage } from '@wix/shoutout-email-model-v3';
import {
  ISection,
  buildCss,
  buildCssInline,
  defaults,
} from '@wix/shoutout-email-react';
import { SecureCss } from '@wix/wix-emails-secure-components';

import { useConfig } from '../../contexts';

type Props = {
  sections: ISection[];
  campaign: IMessage;
};

export function CampaignCss(props: Props) {
  const config = useConfig();
  const [css, setCss] = useState('');

  const { sections, campaign } = props;
  const { composerData } = campaign.data;

  useEffect(() => {
    if (!sections.length) {
      return;
    }
    const allCss = [
      buildCssInline({
        css: composerData.styles.css,
        experiments: config.experiments,
      }),
      buildCss({
        css: composerData.styles.css,
        breakPoint: defaults.defaultBreakpoint,
        composerData,
        experiments: config.experiments,
        sections,
        buildForMobile: true,
      }),
    ].join(' ');
    setCss(prefix(allCss));
  }, [sections, composerData, config]);

  return <SecureCss css={css} />;
}

function prefix(css: string): string {
  let result = '';
  let buffer = '';

  for (const char of css) {
    buffer += char;
    if (char === '{') {
      if (buffer.includes('@media')) {
        result += buffer;
        buffer = '';
      } else {
        result += buffer
          .split(',')
          .map((_) => {
            const selector = _.trim();
            // Class based selectors should have a higher priority.
            if (selector.startsWith('.')) {
              return '#root ' + selector;
            }
            // Default selectors like a, ul, string etc should have lower specificity.
            return selector;
          })
          .join(',');
        buffer = '';
      }
    } else if (char === '}') {
      result += buffer;
      buffer = '';
    }
  }

  result += buffer;
  return result;
}
