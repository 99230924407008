import { useEffect } from 'react';
import { ISection } from '@wix/shoutout-email-react';

export function useAutoscroll(sections: ISection[]) {
  useEffect(() => {
    if (!sections || !sections.length) {
      // No sections, or most probably sections are not yet initialized.
      return;
    }

    const regionId = getRegionId();
    if (!regionId) {
      // Active region ID was not passed as a query parameter, so we don't need to autoscroll.
      return;
    }

    const found = sections.find((section) => section.regionId === regionId);
    if (!found) {
      // Given region ID doesn't exist, corrupted url?
      return;
    }

    const timerId = setTimeout(() => {
      const element = document.querySelector(`[data-region-id="${regionId}"]`);
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [sections]);
}

function getRegionId() {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('region');
  } catch {}
}
