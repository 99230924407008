import React from 'react';
import { LinkWrapper, BdiWrapper } from '@wix/shoutout-email-react';

import css from './region-subscribe.scss';

export function SectionSubscribeConsent(props: any) {
  const {
    section: {
      consent: {
        consentCheckboxText,
        shouldShowConsentLink,
        consentLinkText,
        consentLinkUrl,
        consentTextStyle,
      },
    },
    isRtl,
    checked,
    onChange,
    error,
  } = props;

  const direction = isRtl ? 'rtl' : 'ltr';
  const textAlign = isRtl ? 'right' : 'left';
  const fontSize = '14px';

  const textStyle = {
    ...consentTextStyle,
    textAlign,
    fontSize,
    lineHeight: '18px',
    direction,
  } as React.CSSProperties;

  const linkStyle = {
    ...textStyle,
    textDecoration: 'underline',
    marginLeft: '5px',
  };

  const getColumns = () => {
    const columns = [
      <input
        checked={checked}
        onChange={onChange}
        id="consent-checkbox"
        type="checkbox"
        className={[
          css.checkbox,
          error ? css.checkboxError : css.checkboxDefault,
        ].join(' ')}
        style={{
          marginRight: isRtl ? 0 : '12px',
          marginLeft: isRtl ? '12px' : 0,
        }}
      />,
      <>
        <label style={textStyle} htmlFor="consent-checkbox">
          <LinkWrapper>
            <BdiWrapper text={consentCheckboxText} />
          </LinkWrapper>
        </label>
        {shouldShowConsentLink && (
          <a
            style={linkStyle}
            target="_blank"
            href={consentLinkUrl}
            rel="noreferrer noopener"
          >
            <LinkWrapper>
              <BdiWrapper text={consentLinkText} />
            </LinkWrapper>
          </a>
        )}
      </>,
    ];
    return isRtl ? [...columns.reverse()] : [...columns];
  };

  return (
    <tr>
      <td
        style={{
          textAlign: isRtl ? 'right' : 'left',
          direction: isRtl ? 'rtl' : 'ltr',
          padding: '15px 0 0',
          verticalAlign: 'top',
          width: isRtl ? '100%' : 'initial',
        }}
      >
        {getColumns()[0]}
      </td>
      <td
        style={{
          textAlign: isRtl ? 'right' : 'left',
          direction: isRtl ? 'rtl' : 'ltr',
          padding: '15px 0 0',
          verticalAlign: 'top',
          width: isRtl ? 'initial' : '100%',
        }}
      >
        {getColumns()[1]}
      </td>
    </tr>
  );
}
