import webBiLogger from '@wix/web-bi-logger';
import { Logger } from '@wix/web-bi-logger/dist/src/types';

const config = window.__CONFIG__;
const messageType = window.__CAMPAIGN__.messageType || '';

const logger = webBiLogger
  .factory()
  .updateDefaults({
    src: 36,
    uuid: config.clientId || config.userId,
    page_id: config.messageId,
    ver: process.env.ARTIFACT_VERSION,
    context:
      messageType === 'Ping'
        ? 'apes'
        : messageType === 'Automation'
        ? 'transactional-emails-automations'
        : messageType === 'Transactional'
        ? 'transactional-emails'
        : 'shoutout',
    msid: config.metaSiteId,
  })
  .withUoUContext({
    msid: config.metaSiteId,
  })
  .setMuted(isMuted())
  .logger();

export function useBiLogger(): Logger {
  return logger;
}

// Inside preview/snapshot phase we don't need to fire BI events.
function isMuted() {
  try {
    const params = new URLSearchParams(window.location.search);
    return !!params.get('render');
  } catch {
    return false;
  }
}
