import React, { useCallback } from 'react';
import {
  SectionButton,
  SectionImage,
  SectionFollow,
  SectionAd,
  SectionSpacer,
  SectionDivider,
  SectionMix,
  SectionLogo,
  ISection,
  SectionFooter,
  SectionLink,
  SectionHtml,
  SectionItemGrid,
  SectionText,
  getBodyBackgroundColor,
} from '@wix/shoutout-email-react';
import {
  shoutotAdLinkClick,
  shoutoutLpUserSiteLink,
  shoutoutLpShareButton,
} from '@wix/bi-logger-shout/v2';

import { useCampaign, useConfig } from '../../contexts';
import { SectionVideo, SectionMusic, SectionSubscribe } from '../regions';
import { useBiLogger } from '../../hooks';

const handlers: any = {
  ad: SectionAdWithBi,
  bottomSpacer: SectionSpacer,
  button: SectionButton,
  divider: SectionDivider,
  follow: SectionFollow,
  footer: SectionFooterWithBi,
  html: SectionHtml,
  image: SectionImage,
  item_grid: SectionItemGrid,
  link: SectionLink,
  logo: SectionLogo,
  mix: SectionMix,
  music: SectionMusic,
  subscribe: SectionSubscribe,
  text: SectionText,
  topSpacer: SectionSpacer,
  video: SectionVideo,
};

type Props = {
  sections: ISection[];
};

export function CampaignSections(props: Props) {
  const config = useConfig();
  const campaign = useCampaign();

  const { composerData } = campaign.data;
  const { sections } = props;

  return (
    <>
      {sections.map((section, index) => {
        const Type = handlers[section.type];
        const sectionCssClass = [
          section.cssClass || '',
          `html-email-body-section-${section.type}`,
        ].join(' ');

        return (
          <div data-region-id={section.regionId} key={index}>
            <Type
              backgroundColor={getBodyBackgroundColor(composerData)}
              experiments={config.experiments}
              isRtl={composerData.styles.settings.isRtl || false}
              section={section}
              cssClass={sectionCssClass}
              columnCssClass="html-email-body-column"
            />
          </div>
        );
      })}
    </>
  );
}

function SectionFooterWithBi(props: any) {
  const logger = useBiLogger();

  const onSiteTitleClick = useCallback(() => {
    logger.report(shoutoutLpUserSiteLink({}));
  }, [logger]);

  const onShareClick = useCallback(
    (value: string) => {
      logger.report(shoutoutLpShareButton({ value }));
    },
    [logger],
  );

  return (
    <SectionFooter
      {...props}
      onShareClick={onShareClick}
      onSiteTitleClick={onSiteTitleClick}
    />
  );
}

function SectionAdWithBi(props: any) {
  const logger = useBiLogger();

  const onAscendClick = useCallback(() => {
    logger.report(shoutotAdLinkClick({ origin: 'desktop' }));
  }, [logger]);

  return <SectionAd {...props} onAscendClick={onAscendClick} />;
}
