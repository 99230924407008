import React, { useEffect, useRef } from 'react';
import { shoutoutLpPageView100 } from '@wix/bi-logger-shout/v2';

import { useCampaign } from '../../contexts';
import { useAutoscroll, useBiLogger, useSections } from '../../hooks';
import { CampaignWidthProvider, CampaignConfigProvider } from '../providers';
import { CampaignWrapper } from '../campaign-wrapper';
import { CampaignSections } from '../campaign-sections';
import { CampaignFonts } from '../campaign-fonts';
import { CampaignCss } from '../campaign-css';
import { fedops } from '../../fedops';

import './viewer.scss';

export function Viewer() {
  const campaign = useCampaign();
  const sections = useSections(campaign);
  const logger = useBiLogger();
  const appLoadedReported = useRef(false);

  useAutoscroll(sections);

  useEffect(() => {
    if (!appLoadedReported.current) {
      appLoadedReported.current = true;
      fedops.appLoaded();
    }
    logger.report(
      shoutoutLpPageView100({
        message_id: campaign.messageId,
      }),
    );
  }, [logger, campaign]);

  return (
    <CampaignConfigProvider>
      <CampaignWidthProvider padding={16} campaign={campaign}>
        <div>
          <CampaignFonts />
          <CampaignCss campaign={campaign} sections={sections} />
          <CampaignWrapper padding={16} campaign={campaign}>
            <CampaignSections sections={sections} />
          </CampaignWrapper>
        </div>
      </CampaignWidthProvider>
    </CampaignConfigProvider>
  );
}
