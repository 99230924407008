import { useEffect } from 'react';
import { fontFamilyToWixEmailsFontsHref } from '@wix/shoutout-email-react';

export function CampaignFonts() {
  useEffect(() => {
    Object.values(fontFamilyToWixEmailsFontsHref)
      .map((font) => font.href)
      .forEach(addStylesheet);
  }, []);

  return null;
}

function addStylesheet(url: string) {
  const head = document.head;
  const link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  link.crossOrigin = 'anonymous';
  link.referrerPolicy = 'no-referrer';

  head.appendChild(link);
}
