import React from 'react';

interface Props {
  minWidth?: string;
}

export function ButtonMinWidth(props: Props) {
  const { minWidth } = props;

  if (!minWidth) {
    return null;
  }

  return (
    <img
      ref={(node) => {
        node?.style.setProperty('width', minWidth, 'important');
        node?.style.setProperty('max-width', '100%', 'important');
      }}
      height="1"
      style={{
        display: 'block',
        height: '1px',
        maxHeight: '1px',
      }}
      src="https://static.wixstatic.com/media/5e9922_0a9111966d7648649336e1f1546c5ec9~mv2.gif"
      alt=""
    />
  );
}
