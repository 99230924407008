import React, { useEffect, useState } from 'react';
import {
  SectionSubscribeTitle,
  SectionSubscribeForm,
  SectionSubscribeInputForm,
  SectionSubscribeButton,
  Column,
  Text,
  shorthand,
} from '@wix/shoutout-email-react';
import { HttpClient } from '@wix/http-client';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  shoutoutWebPageLandingSubscriptionClick,
  shoutoutError,
} from '@wix/bi-logger-shout/v2';

import { useBiLogger } from '../../../hooks';
import { fedops } from '../../../fedops';

import { SectionSubscribeConsent } from './region-subscribe-consent';
import { SectionSubscribeInput } from './region-subscribe-input';

type State = {
  email: string;
  consentChecked: boolean;
};

type FormState =
  | { kind: 'default' }
  | { kind: 'error'; error?: string }
  | { kind: 'submitting' }
  | { kind: 'submitted' };

// eslint-disable-next-line no-useless-escape
const emailRe = /^\b[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]{1,50}@[a-zA-Z0-9-]{1,50}(?:\.[a-zA-Z0-9-]{2,20})+$/;

const http = new HttpClient();

export const SectionSubscribe: React.FC<any> = (props) => {
  const {
    section: {
      successMessage,
      errorMessage,
      consent: { shouldRequireConsent },
      formInnerPadding,
      regionId,
    },
    isRtl,
  } = props;

  const logger = useBiLogger();

  const { t } = useTranslation();

  const [formState, setFormState] = useState<FormState>({ kind: 'default' });

  const [state, setState] = useState<State>({
    email: '',
    consentChecked: false,
  });

  useEffect(() => {
    if (formState.kind !== 'submitting') {
      return;
    }
    if (!state.email || !emailRe.test(state.email)) {
      setFormState({
        kind: 'error',
        error: errorMessage || t('COMPOSER.SUBSCRIBE.ERRORS.INVALID_EMAIL'),
      });
    } else if (shouldRequireConsent && !state.consentChecked) {
      setFormState({ kind: 'error' });
    } else {
      logger.report(shoutoutWebPageLandingSubscriptionClick({}));
      fedops.interactionStarted('subscribe-a-contact');

      http
        .post(
          getLocationUrl() +
            `/${regionId}/f14803e3-b99c-4643-b0fa-7586b0001230/event`,
          shouldRequireConsent
            ? {
                emailAddress: state.email,
                event: 'subscribe',
                consent: true,
              }
            : {
                emailAddress: state.email,
                event: 'subscribe',
              },
        )
        .then(() => {
          setFormState({ kind: 'submitted' });
          fedops.interactionEnded('subscribe-a-contact');
        })
        .catch(() => {
          logger.report(
            shoutoutError({
              errorCode: 1500,
              error: 'COMPOSER.SUBSCRIBE.ERRORS.SUBSCRIBE_FAILED',
            }),
          );
          setFormState({
            kind: 'error',
            error: t('COMPOSER.SUBSCRIBE.ERRORS.SUBSCRIBE_FAILED'),
          });
        });
    }
  }, [
    state,
    formState.kind,
    shouldRequireConsent,
    regionId,
    errorMessage,
    logger,
    t,
  ]);

  if (formState.kind === 'submitted') {
    const successFormPadding = { ...formInnerPadding };
    successFormPadding.top = successFormPadding.bottom;
    return (
      <SectionSubscribeForm {...props}>
        <Column
          verticalAlign="top"
          align="center"
          padding={shorthand(successFormPadding)}
        >
          <Text padding="0" align="center">
            <h3 className="so-global-h3">{successMessage}</h3>
          </Text>
        </Column>
      </SectionSubscribeForm>
    );
  }

  return (
    <div>
      <SectionSubscribeTitle {...props} />
      <SectionSubscribeForm {...props}>
        <SectionSubscribeInputForm {...props}>
          <SectionSubscribeInput
            {...props}
            value={state.email}
            onChange={({ target }: any) => {
              if (formState.kind !== 'submitting') {
                const email = target?.value;
                setState((_) => ({ ..._, email }));
              }
            }}
          />
          {shouldRequireConsent && (
            <SectionSubscribeConsent
              {...props}
              error={formState.kind === 'error' && !state.consentChecked}
              checked={state.consentChecked}
              onChange={({ target }: any) => {
                if (formState.kind !== 'submitting') {
                  const consentChecked = target?.checked;
                  setState((_) => ({ ..._, consentChecked }));
                }
              }}
            />
          )}
          {formState.kind === 'error' && formState.error ? (
            <tr>
              <td
                colSpan={2}
                className="so-global-error subscribe-region-error"
                style={{
                  textAlign: isRtl ? 'right' : 'left',
                  direction: isRtl ? 'rtl' : 'ltr',
                  paddingTop: '10px',
                }}
              >
                <span>{formState.error}</span>
              </td>
            </tr>
          ) : null}
        </SectionSubscribeInputForm>
        <SectionSubscribeButton
          {...props}
          onSubscribeClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (formState.kind !== 'submitting') {
              setFormState({ kind: 'submitting' });
            }
          }}
        />
      </SectionSubscribeForm>
    </div>
  );
};

function getLocationUrl() {
  return location.origin + location.pathname;
}
