import React, { ReactNode } from 'react';
import { getEmailBackground, Wrapper } from '@wix/shoutout-email-react';
import { IMessage } from '@wix/shoutout-email-model-v3';

type Props = {
  campaign: IMessage;
  padding: number;
  children: ReactNode;
};

export function CampaignWrapper(props: Props) {
  const { campaign, padding, children } = props;

  const emailBackground = getEmailBackground(campaign.data.composerData);

  return (
    <Wrapper
      backgroundColor={emailBackground.color}
      backgroundRepeat={emailBackground.repeat}
      backgroundUrl={emailBackground.url}
      cssClass="html-emails-background"
      padding={`${padding}px`}
    >
      {children}
    </Wrapper>
  );
}
