import React from 'react';
import { RegionType } from '@wix/shoutout-email-model-v3';
import {
  IGetSectionProps,
  margin,
  padding,
  shorthand,
  getRegionMobileCssClass,
  WrapperItem,
  Section,
  Column,
  Text,
} from '@wix/shoutout-email-react';

import { RegionVideoYoutube } from './region-video-youtube';
import { RegionVideoWix } from './region-video-wix';

export function getVideoSection(props: IGetSectionProps) {
  const { css, region } = props;

  if (region.type !== RegionType.Video) {
    throw new Error('region type must be video');
  }

  const rules = [css['so-global-region-video'], css['so-global-region']];

  return {
    videoType: region.data.videoType,
    url:
      region.data.videoType === 'youtube'
        ? region.data.url
        : region.data.uploadedVideo?.fileUrl,
    cssClass: getRegionMobileCssClass(rules, true),
    outerPadding: margin(rules),
    innerPadding: padding(rules),
  };
}

export function SectionVideo(props: any) {
  const { section, cssClass, columnCssClass } = props;
  const { innerPadding, outerPadding } = section;

  return (
    <WrapperItem>
      <Section
        backgroundColor={section.backgroundColor}
        borderBottom={section.borderBottom}
        borderLeft={section.borderLeft}
        borderRight={section.borderRight}
        borderTop={section.borderTop}
        cssClass={cssClass}
        padding={shorthand(outerPadding)}
      >
        <Column padding={shorthand(innerPadding)} cssClass={columnCssClass}>
          <Text align="center" padding="0px" fontSize="100%">
            {section.videoType === 'youtube' ? (
              <RegionVideoYoutube url={section.url} />
            ) : (
              <RegionVideoWix url={section.url} />
            )}
          </Text>
        </Column>
      </Section>
    </WrapperItem>
  );
}
