import React, { useEffect } from 'react';
import { RegionType } from '@wix/shoutout-email-model-v3';
import {
  padding,
  margin,
  getRegionMobileCssClass,
  IGetSectionProps,
  WrapperItem,
  Section,
  Column,
  Text,
  shorthand,
} from '@wix/shoutout-email-react';

import { useConfig } from '../../../contexts';

export async function getMusicSection({ css, region }: IGetSectionProps) {
  if (region.type !== RegionType.Music) {
    throw Error('Music must be passed');
  }

  const rules = [css['so-global-region-music'], css['so-global-region']];

  return {
    data: region.data,
    outerPadding: margin(rules),
    innerPadding: padding(rules),
    cssClass: getRegionMobileCssClass(rules, true),
  };
}

const DEMO_ALBUM_ID = 'a6b72e22-7e1b-45ad-aac7-1661d974d2ed';

const THEMES: any = {
  light: {
    settingsCollectionId: '931f1bd1-225c-4d11-8cda-bb4d6629b9ff',
  },
  dark: {
    settingsCollectionId: '931f1bd1-dfc4-450a-ac4e-1367840a436e',
  },
};

export function SectionMusic(props: any) {
  const { section, cssClass, columnCssClass } = props;
  const { outerPadding, innerPadding, data } = section;
  const config = useConfig();

  useEffect(() => {
    import('@wix/music-player')
      .then(({ init }) => {
        return init('.so-music-player', {
          isDemoAlbumId:
            data.collection.id === DEMO_ALBUM_ID ? DEMO_ALBUM_ID : false,
          settingsCollectionId: THEMES[data.widgetTheme].settingsCollectionId,
          albumId: data.collection.id,
          metaSiteId: config.metaSiteId,
          locale: config.locale,
        });
      })
      .then((player: any) => {
        player.on('widget:get-window', function () {
          if (config.siteUrl) {
            window.open(config.siteUrl, '_blank');
          }
        });
      });
  }, [config, data]);

  return (
    <WrapperItem>
      <Section
        backgroundColor={section.backgroundColor}
        borderLeft={section.borderLeft}
        borderRight={section.borderRight}
        cssClass={cssClass}
        padding={shorthand(outerPadding)}
      >
        <Column
          backgroundColor={section.backgroundColor}
          cssClass={columnCssClass}
          padding={shorthand(innerPadding)}
        >
          <Text padding="0px" align="center">
            <div className="so-music-player"></div>
          </Text>
        </Column>
      </Section>
    </WrapperItem>
  );
}
