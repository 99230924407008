import React, { useEffect, useRef } from 'react';

export function RegionVideoWix(props: any) {
  const { url } = props;
  const ref = useRef(null);

  useEffect(() => {
    if (url && ref.current) {
      const player = window.WixMGPlayer.create(url, {
        fillAllSpace: true,
        overlay: {
          poster: getPreviewThumbnail(url),
        },
      });
      player.attachToElement(ref.current);
      return () => {
        player.player.destroy();
      };
    }
  }, [url]);

  return (
    <div style={{ paddingBottom: '56.25%', position: 'relative' }}>
      <div
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />
    </div>
  );
}

function getPreviewThumbnail(url: string) {
  return window.VideoGallerySDK.getVideoImage(url, {
    width: 1600,
    height: 900,
    previewIndex: 2,
  });
}
