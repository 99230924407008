import { createContext, useContext } from 'react';

type Config = {
  experiments: { [key: string]: string };
  userId: string;
  clientId: string;
  messageId: string;
  metaSiteId: string;
  userPackage: {
    features?: {
      RemoveWixBranding?: boolean;
    };
  };
  siteUrl: string;
  verticalConfigShowAscendAd: boolean;
  locale: string;
  environment: string;
};

export const ConfigContext = createContext<Config>({
  experiments: {},
  userId: '',
  clientId: '',
  messageId: '',
  metaSiteId: '',
  userPackage: {},
  siteUrl: '',
  verticalConfigShowAscendAd: false,
  locale: 'en',
  environment: '',
});

export function useConfig() {
  const campaign = useContext(ConfigContext);
  return campaign;
}
