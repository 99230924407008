import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';

export function SectionSubscribeInput(props: any) {
  const {
    section: { button, placeholderText },
    isRtl,
    value,
    onChange,
  } = props;

  const { t } = useTranslation();
  const borderRadius = button.borderRadius.toString() || '0px';

  return (
    <tr>
      <td
        colSpan={2}
        style={{
          textAlign: isRtl ? 'right' : 'left',
          fontSize: '11px',
          backgroundColor: '#fff',
          height: '43px',
          direction: isRtl ? 'rtl' : 'ltr',
          borderRadius,
        }}
      >
        <input
          value={value}
          onChange={onChange}
          placeholder={
            placeholderText || t('COMPOSER.SUBSCRIBE.ERRORS.EMAIL_PLACEHOLDER')
          }
          style={{
            display: 'inline-flex',
            boxSizing: 'border-box',
            width: '100%',
            padding: '0 20px',
            margin: 0,
            height: '43px',
            border: 'solid 1px #ccc',
            outline: 'none',
            boxShadow: 'none',
            borderRadius,
          }}
        />
      </td>
    </tr>
  );
}
