import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { migrateBodyPaddings } from '@wix/shoutout-email-react';

import { i18n } from './i18n';
import { CampaignContext, ConfigContext } from './contexts';
import { Viewer } from './components/viewer';
import { parseComposerData, encodeHTMLInRegions } from './client-utils';
import { fedops } from './fedops';

fedops.appLoadStarted();

const config = window.__CONFIG__;
const i18nInstance = i18n(config.locale);

const campaign = migrateBodyPaddings(
  encodeHTMLInRegions(parseComposerData(window.__CAMPAIGN__)),
);

ReactDOM.render(
  <Suspense fallback="">
    <I18nextProvider i18n={i18nInstance}>
      <CampaignContext.Provider value={campaign}>
        <ConfigContext.Provider value={config}>
          <Viewer />
        </ConfigContext.Provider>
      </CampaignContext.Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'),
);
