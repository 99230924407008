import { initI18n } from '@wix/wix-i18n-config';

export function i18n(locale: string) {
  return initI18n({
    locale,
    useSuspense: true,
    asyncMessagesLoader: async (localeToLoad) => {
      try {
        const [emailTranslations, emailReactTranslations] = await Promise.all([
          import(`./assets/locale/messages_${localeToLoad}.json`),
          import(
            `@wix/shoutout-email-react/dist/cjs/assets/locale/messages_${localeToLoad}.json`
          ),
        ]);

        return { ...emailTranslations, ...emailReactTranslations };
      } catch {
        const [emailTranslations, emailReactTranslations] = await Promise.all([
          import(`./assets/locale/messages_en.json`),
          import(
            `@wix/shoutout-email-react/dist/cjs/assets/locale/messages_en.json`
          ),
        ]);

        return { ...emailTranslations, ...emailReactTranslations };
      }
    },
  });
}
