import React from 'react';

export function RegionVideoYoutube(props: any) {
  const { url } = props;
  const id = getYoutubeId(url);

  return (
    <div style={{ paddingBottom: '56.25%', position: 'relative' }}>
      <iframe
        title="Youtube"
        width="100%"
        src={`https://www.youtube.com/embed/${id}?wmode=opaque`}
        frameBorder={0}
        allowFullScreen
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />
    </div>
  );
}

function getYoutubeId(url: string): string {
  const re = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return url.match(re)![1];
}
