import React, { ReactNode } from 'react';
import { IMessage, ICss } from '@wix/shoutout-email-model-v3';
import { WidthProvider } from '@wix/shoutout-email-react';

type Props = {
  campaign: IMessage;
  padding: number;
  children: ReactNode;
};

export function CampaignWidthProvider(props: Props) {
  const { campaign, children, padding } = props;

  const composerData = campaign.data.composerData;
  const width = getBodyWidth(composerData.styles.css) + padding * 2;

  return <WidthProvider value={{ width }}>{children}</WidthProvider>;
}

function getBodyWidth(css: ICss) {
  return parseInt(css['so-global-body'].maxWidth || '700px', 10);
}
