import { IMessage } from '@wix/shoutout-email-model-v3';
import {
  getAdSection,
  getBodyBackgroundColor,
  getBodyBorder,
  getBottomSpacerSection,
  getButtonSection,
  getDividerSection,
  getFollowSection,
  getFooterSection,
  getHtmlSection,
  getImageSection,
  getItemGridSection,
  getLinkSection,
  getLogoSection,
  getMixSection,
  getSubscribeSection,
  getTextSection,
  getTopSpacerSection,
  ISection,
  SectionType,
  shouldShowAd,
} from '@wix/shoutout-email-react';
import { useEffect, useState } from 'react';

import { getMusicSection, getVideoSection } from '../components/regions';
import { useConfig } from '../contexts';

const handlers: any = {
  [SectionType.ad]: getAdSection,
  [SectionType.bottomSpacer]: getBottomSpacerSection,
  [SectionType.button]: getButtonSection,
  [SectionType.divider]: getDividerSection,
  [SectionType.follow]: getFollowSection,
  [SectionType.footer]: getFooterSection,
  [SectionType.html]: getHtmlSection,
  [SectionType.image]: getImageSection,
  [SectionType.itemGrid]: getItemGridSection,
  [SectionType.link]: getLinkSection,
  [SectionType.logo]: getLogoSection,
  [SectionType.mix]: getMixSection,
  [SectionType.music]: getMusicSection,
  [SectionType.subscribe]: getSubscribeSection,
  [SectionType.text]: getTextSection,
  [SectionType.topSpacer]: getTopSpacerSection,
  [SectionType.video]: getVideoSection,
};

export function useSections(campaign: IMessage) {
  const [state, setState] = useState<ISection[]>([]);
  const config = useConfig();

  useEffect(() => {
    const { composerData } = campaign.data;
    const border = getBodyBorder(composerData);

    const sections = [
      { type: SectionType.topSpacer },
      ...composerData.regions,
      { type: SectionType.ad },
      { type: SectionType.bottomSpacer },
    ];

    const lpProps = {
      showAd: shouldShowAd(
        config.verticalConfigShowAscendAd &&
          !config.userPackage?.features?.RemoveWixBranding,
        composerData.regions,
        config.experiments,
        campaign,
      ),
    };

    const builders = sections.map((region) => {
      return build({
        composerData,
        region,
        css: composerData.styles.css,
        border,
        lpProps,
        fetcher: null,
        experiments: config.experiments,
        getVideoSrc: () => null,
        manipulateHtml: true,
        isRtl: composerData.styles.settings.isRtl || false,
        replaceImagePlaceholders: true,
      });
    });

    Promise.all(builders)
      .then((buildersOutput) => {
        return buildersOutput.filter(Boolean) as ISection[];
      })
      .then((nonNullBuildersOutput) => {
        markFirstRegion(nonNullBuildersOutput);
        applyBorderAndColor(campaign, nonNullBuildersOutput);

        setState(nonNullBuildersOutput);
      });
  }, [campaign, config]);

  return state;
}

async function build(props: any): Promise<ISection | null> {
  const { region } = props;
  const handler = handlers[region.type];

  if (handler) {
    const propsForSection = await handler(props);
    if (propsForSection) {
      return {
        ...propsForSection,
        type: region.type,
        regionId: region.regionId,
      };
    }
  }

  return null;
}

function markFirstRegion(sections: ISection[]) {
  if (!sections.length) {
    return;
  }

  const index = sections[0].type === SectionType.topSpacer ? 1 : 0;
  sections[index].cssClass = `${sections[index].cssClass} first-region-section`;
}

function applyBorderAndColor(campaign: IMessage, sections: ISection[]) {
  if (!sections.length) {
    return;
  }

  const { composerData } = campaign.data;

  const border = getBodyBorder(composerData);
  const backgroundColor = getBodyBackgroundColor(composerData);

  sections.forEach((section) => {
    section.borderLeft = border;
    section.borderRight = border;
    section.backgroundColor = backgroundColor;
  });

  sections[0].borderTop = border;
  sections[sections.length - 1].borderBottom = border;
}
