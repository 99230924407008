import React, { ReactNode } from 'react';
import { ConfigProvider, StandaloneImage } from '@wix/shoutout-email-react';

import { ButtonMinWidth } from '../min-button-width';

type Props = {
  children: ReactNode;
};

const config = {
  renderComments: false,
  wrapPlaceholders: true,
  implementations: {
    Image: StandaloneImage,
    ButtonMinWidth,
  },
};

export function CampaignConfigProvider(props: Props) {
  const { children } = props;

  return <ConfigProvider value={config}>{children}</ConfigProvider>;
}
