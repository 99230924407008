export function parseComposerData(campaign: any) {
  const composerData =
    typeof campaign.data.composerData === 'string'
      ? JSON.parse(campaign.data.composerData)
      : campaign.data.composerData;

  campaign.data.composerData = composerData;
  return campaign;
}

export function encodeHTMLInRegions(campaign: any) {
  const composerData = campaign.data.composerData;
  composerData.regions = composerData.regions.map(encodeHTMLInRegion);
  return campaign;
}

function encodeHTMLInRegion(region: any) {
  const isIgnored = ['text', 'mix', 'button', 'html', 'item_grid'].includes(
    region.type,
  );
  if (!isIgnored) {
    region.data = encodeHTMLInJSON(region.data);
  }

  return region;
}

function encodeHTMLInJSON(json: any) {
  return JSON.parse(encodeHTML(JSON.stringify(json)));
}

function encodeHTML(htmlLike: string) {
  return htmlLike.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}
